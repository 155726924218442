<template>
  <th-page-wrapper
    class="processes-edit"
    :title="$t('common.resource.process.singular')"
    color="var(--secondary-color)"
    align-title="center"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
    @close-requested="$router.push({ name: 'processes-list' })"
  >
    <div>
      <div class="export">
        <el-button
          v-permissions="{ scopes: ['inventory:processes:export'] }"
          type="primary"
          :loading="loadingExport"
          @click="exportItems"
        >
          {{ $t('pages.processes.edit.export_items') }}
        </el-button>
      </div>
      <processes-form ref="form" />
    </div>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pRetry from 'p-retry'
import * as axios from 'axios'
import ProcessesForm from './components/form.vue'
import { exportMapFactory } from '@/utils/objects'

export default {
  name: 'ProcessesEdit',
  components: {
    ProcessesForm
  },
  data() {
    return {
      loadingExport: false,
      exportMapList: [
        {
          name: 'code',
          label: this.$t('pages.processes.items.headers.code')
        },
        {
          name: 'amount',
          label: this.$t('pages.processes.items.headers.amount')
        },
        {
          name: 'location_custom_id',
          label: this.$t('pages.processes.items.headers.location_custom_id')
        }
      ]
    }
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'inventory:processes',
        returnedPath: { name: 'processes-list' },
        isNew: this.isNew
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },
    handleDelete() {
      this.$refs.form.handleDelete()
    },
    async download() {
      const exportMapArgs = exportMapFactory(this.exportMapList)
      const query = { format: 'csv', export_map: exportMapArgs }
      const processId = this.$route.params.id
      const results = await th.processes().getItems(processId, query)
      const downloadUrl = results.data.url

      const inst = axios.create()

      const run = () =>
        inst({
          method: 'HEAD',
          url: downloadUrl,
          headers: {
            crossDomain: true
          }
        }).then((response) => {
          if (![200, 404].includes(response?.status)) {
            throw new pRetry.AbortError('unmatched response code')
          }

          return null
        })

      await pRetry(run, {
        retries: 10,
        minTimeout: 3000,
        maxTimeout: 10000,
        factor: 1.5,
        onFailedAttempt: (error) => {
          // eslint-disable-next-line no-console
          console.log(
            `Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} attempts left.`
          )
        }
      })

      const a = document.createElement('a')
      a.href = downloadUrl
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
    },
    async exportItems() {
      const query = {}
      try {
        this.loadingExport = true
        await this.download(query)
        this.loadingExport = false
      } catch (err) {
        this.$logException(err, { trackError: false })
        this.loadingExport = false
        this.$notify.error({
          title: 'Error',
          message: err.message
        })
      }
    }
  }
}
</script>

<style scoped>
.export {
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: center;
  align-items: center;
  padding: 0px 20px;
  height: 100%;
}
</style>
