<template>
  <div class="form-wrapper mouseflow-hide">
    <div class="edit-form-wrapper">
      <el-form
        ref="form"
        v-loading="loading"
        :model="form"
        :rules="rules"
        label-position="top"
      >
        <el-col
          class="form-column"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <el-form-item prop="name" :label="$t('common.headers.name.title')">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item
            prop="location"
            required
            :label="$t('common.headers.location.title')"
          >
            <el-select v-model="form.location" resource="locations">
              <el-option
                v-for="location in locations"
                :key="location.id"
                :value="location.id"
                :label="location.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            prop="assigned_staff"
            required
            :label="$t('pages.processes.all.headers.assigned_staff')"
          >
            <el-select v-model="form.assigned_staff" resource="assigned_staff">
              <el-option
                v-for="s in staff"
                :key="s.id"
                :value="s.id"
                :label="s.displayname || fullName(s)"
              />
            </el-select>
          </el-form-item>
          <template v-if="!isNew">
            <el-form-item
              prop="started_at"
              :label="$t('pages.processes.all.headers.started_at')"
            >
              <el-input v-model="computedStartedAt" disabled />
            </el-form-item>
            <el-form-item
              prop="finished_at"
              :label="$t('pages.processes.all.headers.finished_at')"
            >
              <el-input v-model="computedFinishedAt" disabled />
            </el-form-item>
            <el-form-item
              prop="status"
              :label="$t('pages.processes.all.headers.status')"
            >
              <el-input v-model="computedStatus" disabled />
            </el-form-item>
            <el-form-item
              :label="$t('pages.processes.all.headers.total_amount')"
            >
              <el-input v-model="computedAmount" disabled />
            </el-form-item>
            <el-form-item
              :label="$t('pages.processes.all.headers.total_distinct')"
            >
              <el-input v-model="computedDistinctAmount" disabled />
            </el-form-item>
            <el-form-item
              :label="$t('pages.processes.all.headers.total_attendees')"
            >
              <el-input v-model="computedAttendeesAmount" disabled />
            </el-form-item>
            <el-form-item :label="$t('pages.processes.all.headers.result')">
              <el-table
                :data="form.result.items"
                :empty-text="$t('components.th_datatable.no_data')"
              >
                <el-table-column
                  prop="code"
                  :label="$t('pages.processes.items.headers.code')"
                />
                <el-table-column
                  prop="amount"
                  :label="$t('pages.processes.items.headers.amount')"
                />
              </el-table>
            </el-form-item>
          </template>
        </el-col>
      </el-form>
    </div>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import fullName from '@/utils/full-name'
import { useMessagesStore } from '@/store/messages'

export default {
  name: 'ProcessesForm',
  data() {
    return {
      loading: false,
      form: {
        name: undefined,
        started_at: undefined,
        finished_at: undefined,
        status: 'created',
        result: {
          items: undefined
        },
        location: undefined,
        assigned_staff: undefined
      },
      payload: {},
      fields: [
        'name',
        'started_at',
        'finished_at',
        'status',
        'result',
        'location',
        'assigned_staff'
      ],
      rules: {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.processes.edit.form.field_warnings.required'
            )
          },
          {
            min: 1,
            max: 128,
            message: this.$t(
              'pages.processes.edit.form.field_warnings.max_length',
              { length: 128 }
            )
          }
        ]
      },
      locations: [],
      staff: []
    }
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    navigationAfterCreation() {
      return this.$store.getters['Config/getNavigationAfterCreation']
    },
    translations() {
      return {
        cancelled: this.$t('common.processes.statuses.cancelled.title'),
        created: this.$t('common.processes.statuses.created.title'),
        finished: this.$t('common.processes.statuses.finished.title'),
        paused: this.$t('common.processes.statuses.paused.title'),
        resumed: this.$t('common.processes.statuses.resumed.title'),
        started: this.$t('common.processes.statuses.started.title')
      }
    },
    computedStatus: {
      get() {
        return this.translations[this.form?.status] || '-'
      },

      set(nVal, oVal) {
        // nothing for now
      }
    },
    computedStartedAt() {
      if (!this.form.started_at) return '-'
      return this.$date.formatDateTimeWithTimezone(this.form.started_at)
    },
    computedFinishedAt() {
      if (!this.form.finished_at) return '-'
      return this.$date.formatDateTimeWithTimezone(this.form.finished_at)
    },
    computedAmount() {
      const items = safeGet(this.form, 'result.items')

      if (!items || items.length === 0) return 0

      return items.reduce((acc, item) => acc + item.amount, 0)
    },
    computedDistinctAmount() {
      const items = safeGet(this.form, 'result.items')

      if (!items || items.length === 0) return 0

      const codeSet = new Set(items.map((i) => i.code))

      return codeSet.size
    },
    computedAttendeesAmount() {
      const items = safeGet(this.form, 'result.items')

      if (!items || items.length === 0) return 0

      const attendeeSet = new Set(
        items.map((i) => i.scanned_by).filter((i) => i !== null)
      )

      return attendeeSet.size
    }
  },
  created() {
    this.$emitter.on('save-requested', this.submitForm)
  },
  async mounted() {
    if (!this.isNew) this.fetch(this.$route.params.id)

    try {
      await Promise.all([this.fetchLocations(), this.fetchStaff()])
    } catch (err) {
      this.$logException(err, { trackError: false })
    }
  },
  methods: {
    fullName,
    async fetch(id) {
      const errorMessage = this.$t(
        'pages.processes.edit.form.errors.fetch.code_XXX.content'
      )
      try {
        this.loading = true
        const { data = {} } = await th.processes().get(id)

        if (data.id) {
          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    submitForm() {
      const warningMessage = this.$t(
        'pages.processes.edit.form.warnings.invalid_inputs.contents'
      )
      this.validate('form', (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: warningMessage
          })
        }

        this.loading = true
        const payload = this.makeHandleableProcessesBody(this.form)

        if (this.isNew) return this.create(payload)
        this.alter(this.payload.id, payload)
      })
    },
    validate(formName = 'form', cb) {
      this.$refs[formName].validate(cb)
    },
    async create(payload) {
      const errorMessage = this.$t('common.error.action.create.single', {
        resource: this.$t('common.resource.process.singular')
      })

      try {
        const { data = {}, errors = [] } = await th.processes().create(payload)

        this.loading = false
        if (data.id) {
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.create.single', {
              resource: this.$t('common.resource.process.singular')
            })
          })

          if (this.navigationAfterCreation === 'edit') {
            this.$router.push({
              name: 'processes-edit',
              params: { id: data.id }
            })
          } else {
            this.$router.push({ name: 'processes-list' })
          }
        }

        if (errors.length) {
          this.$log.debug(
            'processes-create: errors on success',
            JSON.stringify(errors, null, 2)
          )
          errors.forEach((errorObj) => {
            useMessagesStore().setLocalMessage({
              id: errorObj.id,
              label: errorObj.label,
              operation: 'local_message',
              payload: errorObj.errorDetails
            })
          })
        }
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    async alter(id, payload) {
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.process.singular')
      })
      try {
        const { data = {} } = await th.processes().update(id, payload)

        if (data.id) {
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.update.single', {
              resource: this.$t('common.resource.process.singular')
            })
          })

          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    makeHandleableProcessesBody(form) {
      return form
    },
    handleItem(item) {
      this.payload = item

      const cleanedPayload = pick(item, this.fields)

      if (!cleanedPayload.result) {
        cleanedPayload.result = {}
      }
      if (!cleanedPayload.result.items) {
        cleanedPayload.result.items = []
      }

      this.form = cleanedPayload
    },
    resetForm(formName) {
      if (this.isNew) {
        this.$refs.form.resetFields()
      } else {
        this.handleItem(this.payload)
      }
    },
    fetchLocations: async function () {
      const { data } = await th
        .stocks()
        .getLocations({ query: { deleted: false } })
      this.locations = data || []
    },
    fetchStaff: async function () {
      const { staff = [] } = await this.$resourceFetch('staff')
      this.staff = staff
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(
        this.payload.name || this.payload.id
      )
      if (confirm) this.deleteProcess()
    },
    async deleteProcess() {
      const resource = this.$t('common.resource.process.singular')
      const errorMessage = this.$t('common.forms.error.delete_fail.title', {
        resource
      })
      try {
        await th.processes().delete(this.payload.id)

        this.resetForm()
        this.$router.push({ name: 'processes-list' })
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
}

.form-wrapper :deep(.el-form) {
  display: flex;
  justify-content: center;
}

.edit-form-wrapper {
  flex-grow: 2;
  flex-shrink: 0;
  flex-basis: 80%;
  height: 100%;
  overflow: auto;
}

.form-column {
  margin: 0;
  padding: 1em;
}

.midst-body {
  margin-top: 40px;
}

.required-marker {
  position: absolute;
  right: -15px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: center;
  align-items: center;
}

.form-header {
  font-weight: bold;
  margin-top: 20px;
}

.el-form-item {
  margin: 10px 0 20px 0;
}

@media (min-width: 1200px) {
  .form-wrapper :deep(.el-col:first-of-type) {
    padding-right: 100px;
  }
}

.el-input__icon {
  font-size: 18px;
  position: absolute;
  right: 15px;
}

.vjs-tree :deep(*) {
  font-family: monospace;
}
</style>
